.lazy-load-image-background.blur {
  -webkit-filter: blur(15px);
          filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transition: -webkit-filter .3s;
  transition: -webkit-filter .3s;
  transition: filter .3s;
  transition: filter .3s, -webkit-filter .3s;
}

.lazy-load-image-background.blur > img {
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}
