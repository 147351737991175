.lazy-load-image-background.opacity {
  background-image: none !important;
  opacity: 0;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
  opacity: 1;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}
